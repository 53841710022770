var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Clone List" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeClone) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Clone list items from another existing list. Add ")
        ]),
        _c("code", [_vm._v("pull")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("put")]),
        _c("span", [_vm._v(" to ")]),
        _c("code", [_vm._v("group")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("h6", [_vm._v("Tag Source")]),
              _c(
                "draggable",
                {
                  staticClass: "mb-1 cursor-move",
                  attrs: {
                    list: _vm.list1,
                    group: { name: "tags", pull: "clone", put: false }
                  }
                },
                _vm._l(_vm.list1, function(listItem, index) {
                  return _c("b-badge", { key: index, staticClass: "mr-25" }, [
                    _vm._v(" " + _vm._s(listItem) + " ")
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("h6", [_vm._v("Your Tags")]),
              _c(
                "draggable",
                {
                  staticClass: "mb-1 cursor-move",
                  attrs: { list: _vm.list2, group: { name: "tags" } }
                },
                _vm._l(_vm.list2, function(listItem, index) {
                  return _c("b-badge", { key: index, staticClass: "mr-25" }, [
                    _vm._v(" " + _vm._s(listItem) + " ")
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "prism",
                { staticClass: "rounded", attrs: { language: "javascript" } },
                [_vm._v(" Tag Source: " + _vm._s(_vm.list1) + " ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "prism",
                { staticClass: "rounded", attrs: { language: "javascript" } },
                [_vm._v(" Added tags: " + _vm._s(_vm.list2) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }