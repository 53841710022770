var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Animation" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAnimation) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Add animation to changes using ")]),
        _c("code", [_vm._v("transition-group")]),
        _c("span", [_vm._v(".")])
      ]),
      _c("h6", { staticClass: "text-primary font-weight-bold mb-2" }, [
        _vm._v(" People Group ")
      ]),
      _c(
        "draggable",
        {
          staticClass: "list-group list-group-flush cursor-move",
          attrs: { tag: "ul" },
          model: {
            value: _vm.list,
            callback: function($$v) {
              _vm.list = $$v
            },
            expression: "list"
          }
        },
        [
          _c(
            "transition-group",
            { attrs: { type: "transition", name: "flip-list" } },
            _vm._l(_vm.list, function(listItem) {
              return _c(
                "b-list-group-item",
                { key: listItem.email, attrs: { tag: "li" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-avatar", {
                        attrs: { text: listItem.name.slice(0, 2) }
                      }),
                      _c(
                        "div",
                        { staticClass: "ml-25" },
                        [
                          _c(
                            "b-card-text",
                            { staticClass: "font-weight-bold mb-0" },
                            [_vm._v(" " + _vm._s(listItem.name) + " ")]
                          ),
                          _c("small", [_vm._v(_vm._s(listItem.email))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "prism",
        { staticClass: "rounded mt-2", attrs: { language: "javascript" } },
        [_vm._v(" " + _vm._s(_vm.list) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }